<template>
  <div>
    <div style="position: fixed; z-index: 1; background-color: white; width: 100%; top:55px;">
    <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

    </v-breadcrumbs>
    </div><br><br><br>
    <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
    <v-row>
      <v-col align="center">
        <v-text-field class="centered-input" v-model="search" append-icon="mdi-magnify" label="Search Module..."
          single-line hide-details style="width: 50%"></v-text-field>
      </v-col>
    </v-row>
    <!-- <v-container fluid> -->
    
    <v-row dense>
      <v-col class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3" v-for="linkname in filteredSortSearchList"
        :key="linkname.name" :cols="linkname.flex">
        <router-link :to="{ name: linkname.name }" style="text-decoration: none">
          <v-card style="margin: 8px;border: 2px solid black !important;border-radius:10px" id="card-hover">
            <br />
            <v-img height="150px">
              <center>
                <!-- <v-avatar class="avatar-icon" size="70">
                    <span>
                      <v-icon size="55" class="link-icon">{{linkname.icon}}</v-icon>
                    </span>
                  </v-avatar> -->
                <span :data-letters="linkname.icontext"></span>
                <v-card-title v-text="linkname.textval" class="card-title pt"></v-card-title>

              </center>
            </v-img>
            <v-card-actions>

              <v-avatar v-if="linkname.size == 0" color="red darken-1" size="22">
                <span class="white--text" :title="'#' + linkname.size + ' Field Added'">{{ linkname.size }}</span>
              </v-avatar>
              <v-avatar v-else color="success" size="43">
                <span class="white--text" :title="'#' + linkname.size + ' Field Added'">{{ linkname.size }}</span>
              </v-avatar>
              <v-spacer></v-spacer>

              <router-link :to="{ name: linkname.name }" style="text-decoration: none">
                <v-btn icon size="22">
                  <v-icon :title="'Click here to Add ' + linkname.textval" class="add-plus-icon">mdi-plus-circle</v-icon>
                </v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
</div>
  </div>
</template>

<script>
import axios from 'axios'
import "@/assets/css/color.css";
export default {

  data() {
    return {
      routerLinkList: [],
      search: '',
      init_loading: false,
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/home',
        },
        {
          text: localStorage.getItem("jhhjbyj"),
          disabled: false,
          href: '/sublink',
        },
        {
          text: 'Add Master',
          disabled: true,
          href: 'breadcrumbs_link_2',
        },
      ],
    };
  }, // end of data()
  mounted() {
    this.init_loading = true;
    axios.get("Admin/adminMasterMenu")
      .then(res => {
        //window.console.log("res"+res.data)
        if (res.data.msg == "200") {
          this.init_loading = false;
          this.data = res.data
          this.routerLinkList = res.data.routerLinkList
          console.log(this.routerLinkList)
        }
        else {
          this.init_loading = false;
          console.log('aa');
        }
      })
      .catch(error => {
        window.console.log(error)
      })
  },
  computed: {

    filteredSortSearchList() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      // if (!this.search) return this.routerLinkList.sort(compare);
      console.log(compare);
      return this.routerLinkList.filter(tbdata => {
        return tbdata.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
      })
    }
  }
  // end of computed()

};
</script>

<style scoped>
.pt {
  padding-top: 8px;
}

[data-letters]:before {
  margin-bottom: 0 !important;
  margin-top: 2rem;
  content: attr(data-letters);
  display: inline-block;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  border: 3px solid;
  box-sizing: initial;
  background: black;
  /* background: linear-gradient(to right, darkgray, white); */
  /* background:linear-gradient(130deg, hsla(190, 99%, 30%, 1) 0%, hsla(226, 50%, 65%, 1) 179%); */
  vertical-align: middle;
  color: white;
}

.card-title {
  font-weight: 400;
  /* padding-left: 27px !important; */
  justify-content: center !important;
  word-break: keep-all !important;
}

.add-plus-icon {
  color: #616161
}

.avatar-icon {
  border-color: #3f51b5;
  background: white;
}

.link-icon {
  color: #E9AA22;
}

#card-hover {
  background: linear-gradient(to right, darkgray, white);
  /* background: linear-gradient(90deg, hsla(208, 67%, 81%, 1) 0%, hsla(37, 65%, 85%, 1) 50%, hsla(301, 65%, 83%, 1) 100%); */
}

#card-hover:hover {
  background: #ededed;
}

#card-hover:hover .link-icon {
  color: #E9AA22;
}

#card-hover:hover .avatar-icon {
  border-color: #3f51b5;
  background: white;
}

#card-hover:hover .card-title {
  color: #777777;


}

#card-hover:hover .add-plus-icon {

  border: none;
  border-radius: 100%;
  color: white;
  background: #3f51b5;
}


</style>